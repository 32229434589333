import React, { createContext, useState } from 'react';
import { I18n as I18nType } from './components/MinOrderQtyDialog/MinOrderQtyDialog';
import { getConfigFromLocalStorage } from './hooks/useFetchConfig';
import { formatISODate } from './_lib/util';
export const I18nContext = createContext<any>(null);
export const I18nSetterContext = createContext<(i18n: any) => void>(() => {});

function I18nWrapper(props: any) {
  const format = (text: string, variables: Array<any>) => {
    let result = text;
    const count = variables.length;
    for (let i = 1; i <= count; i++) {
      result = result.replace('${' + i + '}', variables[i - 1]);
    }
    return result;
  };

  const capitalize = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const uppercase = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.toUpperCase();
  };

  const lowercase = (s: string) => {
    if (typeof s !== 'string') return '';
    return s.toLowerCase();
  };

  const reactStringReplace = (
    str: string,
    labelStringFormatter: any,
    ReactComponentFormatter: any,
    classes?: any
  ) => {
    var parts = str.split(/\{|\}/g);
    var fn = labelStringFormatter,
      output = [];
    for (var i = 0; i < parts.length; i++) {
      output[i] = fn(parts[i], `reactStringReplace-${i}`);
      // toggle between the two functions
      fn = fn === labelStringFormatter ? ReactComponentFormatter : labelStringFormatter;
    }

    const resultClass = typeof classes === 'undefined' ? 'grid-x align-middle' : classes;

    return <div className={resultClass}>{output}</div>;
  };

  // const formatLongTypedString = (str: string, arg: any) => {
  //   return str.replace(new RegExp('{[a-z]+}', 'gi'), function (match: string, index) {
  //     const key = match.slice(1, match.length - 1);
  //     const val = arg[key].value;
  //     return formatType(val, arg[key].type);
  //   });
  // }

  const formatType = (res: any) => (
    val: any,
    type: 'date' | 'id' | 'longdate' | 'shortdate' | 'percent' | 'currency' | 'integer' | any
  ) => {
    switch (type) {
      case 'id':
        return val || val === 0 ? `${val}` : '--';
      case 'date':
        return val ? formatISODate(new Date(val).toString()).slice(3) : '--';
      case 'longdate':
        return val ? formatISODate(new Date(val).toString()).slice(3) : '--';
      case 'shortdate':
        return val ? formatISODate(new Date(val).toString()).slice(3) : '--';
      case 'percent':
        return val || +val === 0 ? (typeof +val === 'number' ? `${val.toFixed(1)}%` : val) : '--';
      case 'currency':
        const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
        const currencySymbol = localStorage.getItem('PxCurrencySymbol');
        const decimalPointLimit: number = currencyPrecision ? +currencyPrecision : 2;
        return !isNaN(parseInt(val)) && +val !== 0
          ? `${currencySymbol || '$'}${(+val.toFixed(decimalPointLimit)).toLocaleString('en', {
              useGrouping: true,
              minimumFractionDigits: decimalPointLimit,
            })}`
          : '--';
      case 'currency-string':
        const currencySymbols = localStorage.getItem('PxCurrencySymbol');
        return `${currencySymbols || '$'}${val}`;
      case 'integer':
        return `${val ? val.toFixed(0) : '--'}`;
      default:
        return val || val === 0 ? val : '--';
    }
  };

  const [I18nState, setI18nState] = useState<I18nType>({
    _format: format,
    _capitalize: capitalize,
    _uppercase: uppercase,
    _lowercase: lowercase,
    _formatType: formatType({}),
    _reactStringReplace: reactStringReplace,
  } as I18nType);

  const setI18n = (i18n: any) => {
    setI18nState({
      ...i18n,
      _format: format,
      _capitalize: capitalize,
      _uppercase: uppercase,
      _lowercase: lowercase,
      _formatType: formatType(i18n),
      _reactStringReplace: reactStringReplace,
    });
  };

  return (
    <>
      {I18nState ? (
        <I18nSetterContext.Provider value={setI18n}>
          <I18nContext.Provider value={I18nState}>{props.children}</I18nContext.Provider>
        </I18nSetterContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
}

export const getI18n = () => {
  return new Promise((resolve: any) => {
    const getI18nByLang = (data: any) => {
      const localeFromConfig = localStorage.getItem('Px-localeFromConfig');
      const browserLocale = navigator.languages ? navigator.languages : [navigator.language];
      let chosenLocale: any = null;
      if (localeFromConfig && data.hasOwnProperty(localeFromConfig)) {
        // If user chose a locale from config, use it
        chosenLocale = localeFromConfig;
      } else {
        // Or try using the locale from browser
        Object.keys(data).forEach((i18nLang: string) => {
          if (!chosenLocale) {
            browserLocale.forEach((l: string) => {
              if (!chosenLocale && i18nLang.indexOf(l) > -1) {
                chosenLocale = i18nLang;
              }
            });
          }
        });
      }
      const localConfig: any = getConfigFromLocalStorage();
      chosenLocale = localConfig?.language;
      if (chosenLocale && !localeFromConfig) {
        localStorage.setItem('Px-localeFromConfig', chosenLocale);
      }
      if (
        chosenLocale &&
        data[chosenLocale] &&
        typeof data[chosenLocale][Object.keys(data[chosenLocale])[0]] === 'string'
      ) {
        document.documentElement.lang = chosenLocale;
        return data[chosenLocale];
      }
      return {};
    };

    // Try loading i18n from localStorage
    const localI18n = localStorage.getItem('PxI18n');
    if (localI18n) {
      const i18n = getI18nByLang(JSON.parse(localI18n));
      resolve(i18n);
    } else {
      // console.log('rocess.env.REACT_APP_STAGE ' + process.env.REACT_APP_STAGE);
      let publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway/saas-i18n/v1';
      if (!process.env.REACT_APP_API_URL) {
        if (process.env.REACT_APP_STAGE === 'test') {
          publicDataEndpoint =
            'https://s4z4motqq3.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n/v1';
        } else if (process.env.REACT_APP_STAGE === 'prod') {
          publicDataEndpoint =
            'https://qkexusfqqf.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n/v1';
        } else if (process.env.REACT_APP_STAGE === 'release') {
          publicDataEndpoint =
            'https://94o107xk88.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n/v1';
        }
      }
      fetch(publicDataEndpoint).then(async (res: any) => {
        const data = await res.json();
        if (data.status === 200) {
          const remoteI18n = Object.assign({}, data.results);
          let i18n: any = {};
          if (Object.keys(remoteI18n).length) {
            // Only store i18n if it had content
            localStorage.setItem('PxI18n', JSON.stringify(remoteI18n));
            i18n = getI18nByLang(remoteI18n);
          }
          resolve(i18n);
        } else {
          resolve({});
        }
      });
    }
  });
};

export default I18nWrapper;
