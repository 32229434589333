import React, { Suspense, useContext, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as configActions from './redux/actions/configActions';
import * as systemMessageAction from './redux/actions/systemMessageAction';
import * as networkSystemMessageAction from './redux/actions/networkSystemMessageAction';
import * as stockActions from './redux/actions/stockGroupActions';
import * as bannerAction from './redux/actions/bannerAction';
import * as userActions from './redux/actions/userActions';
import * as urlActions from './redux/actions/urlActions';
import * as inventoryActions from './redux/actions/inventoryActions';
import * as salesOrderActions from './redux/actions/salesOrderActions';

import Theme from './Theme';

// LAYOUTS
import FrameLayout from './layouts/layout-dashboard-frame';

// VIEWS
import Homepage from './views/Homepage/Homepage';
import Stock from './views/Stock/Stock';
// ALL
import TopMenu from './components/TopMenu/TopMenu';
import Progress from './components/Progress/Progress';

//ERROR HANDLER
import useDataService from './hooks/useDataService';

import apiToUrlMap from './ApiMapping';
import { PXNSettingsResponse } from './types/StockListTypes';
import { accountsetupPrivateRoute, defaultParticipant, publicPrivateRoute } from './_lib/constant';
import { iSystemMessage } from './types/ISystemTypes';
import Footer from './components/Footer/Footer';
import ProofOfExportDialog from './components/ProofOfExportDialog/ProofOfExportDialog';
import { IPOEServerResponse } from './types/POETypes';
import Widgets from './widgets';
import useFetchConfig, { getConfig } from './hooks/useFetchConfig';
import DeactivatedTenant from './widgets/PersistedScreens/DeactivatedTenant';
import {
  getPXNWarehouseObj,
  processPXNStockListSettings,
  logoutUserWithoutAcknowledgement,
  checkNullOrUndefined,
  getNetworkSystemMessageState,
} from './_lib/util';
import ErrorView from './components/ErrorHandlers/ErrorView';
import PublicAndPrivateRoute from './routes/PublicAndPrivateRoute';
import { I18nContext, I18nSetterContext } from './I18n';
import { isViewOnlyCustomer, viewOnlyCustomerPathValid } from './_lib/tagUtil';
import FullWidthLayout from './layouts/layout-dashboard-fullwidth';
import {
  missingDocumentNotification,
  missingRelatedStatus,
} from './components/AccountSetup/AccountSetupUtils';
import AccountSetupPrivate from './routes/AccountSetupPrivate';

import FiltersPersistWrapper from './components/FiltersPersistWrapper/FiltersPersistWrapper';
import { format } from 'date-fns';
const ComplianceQuestionnaire = React.lazy(
  () => import('./views/ComplianceQuestionnaire/ComplianceQuestionnaire')
);
const Login = React.lazy(() => import('./components/Login/AuthWrapper'));
const OrderConfirmation = React.lazy(() => import('./views/OrderConfirmation/OrderConfirmation'));
const ResetPassword = React.lazy(() => import('./components/Login/ResetPasswordFlow'));
const PaymentConfirmation = React.lazy(
  () => import('./views/PaymentConfirmation/PaymentConfirmation')
);
const AccountSetupDocumentsV1 = React.lazy(
  () => import('./views/AccountSetupDocuments/AccountSetupDocumentsV1')
);
const ShippingConsentForm = React.lazy(
  () => import('./views/ShippingConsentForm/ShippingConsentForm')
);
const CheckoutConfirmationV1 = React.lazy(
  () => import('./views/CheckoutConfirmation/CheckoutConfirmationV1')
);
const CheckoutV1 = React.lazy(() => import('./views/Checkout/CheckoutV1'));
const AddToExistingOrderV1 = React.lazy(
  () => import('./views/AddToExistingOrder/AddToExistingOrderV1')
);
const CartV1 = React.lazy(() => import('./views/Cart/CartV1'));
const OrdersV1 = React.lazy(() => import('./views/Orders/OrdersV1'));
const OrderDetailsV1 = React.lazy(() => import('./views/Orders/OrderDetailsV1'));
const AuctionBidding = React.lazy(() => import('./views/AuctionBidding/AuctionBidding'));
const MasterPurchaseAgreement = React.lazy(
  () => import('./views/MasterPurchaseAgreement/MasterPurchaseAgreement')
);
const AuctionList = React.lazy(() => import('./views/AuctionList/AuctionList'));
const CreateAccount = React.lazy(() => import('./views/CreateAccount/CreateAccount'));
const EmailVerfication = React.lazy(
  () => import('./components/EmailVerification/EmailVerification')
);
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const StockAlerts = React.lazy(() => import('./views/StockAlerts/StockAlerts'));
const Terms = React.lazy(() => import('./views/Terms/Terms'));
const Returns = React.lazy(() => import('./views/Returns/Returns'));
const Account = React.lazy(() => import('./views/Account/Account'));
const PoAwards = React.lazy(() => import('./views/POFulfillment/PoAwards'));
const AccountSetupDetails = React.lazy(
  () => import('./views/AccountSetupDetails/AccountSetupDetails')
);
const AccountSetupDocuments = React.lazy(
  () => import('./views/AccountSetupDocuments/AccountSetupDocuments')
);
const AccountSetup = React.lazy(() => import('./views/AccountSetup/AccountSetup'));
const AccountSetupEUS = React.lazy(() => import('./views/AccountSetupEUS/AccountSetupEUS'));
const AccountSetupEUSAddendum = React.lazy(
  () => import('./views/AccountSetupEUSAddendum/AccountSetupEUSAddendum')
);
const AccountSetupEUSV2 = React.lazy(() => import('./views/AccountSetupEUS/AccountSetupEUSV2'));
const AccountSetupEUSV1 = React.lazy(() => import('./views/AccountSetupEUS/AccountSetupEUSV1'));
const ShippingAndBillingPreferences = React.lazy(
  () => import('./views/ShippingAndBillingPreferences/ShippingAndBillingPreferences')
);

const PrivateRoute = ({ children, fullWidth, ...rest }: any) => {
  const dispatch = useDispatch();
  const setI18n = useContext(I18nSetterContext);
  const I18n = useContext(I18nContext);
  const { fetchUrl, openSnackBar, closeSnackBar } = useDataService();
  const [settingsApiErr, setSettingsApiErr] = useState<any>();
  const history = useHistory();
  history.listen((location: any) => {
    if (!location.search) window.scrollTo(0, 0);
  });

  const fetchCartSettings = async () => {
    try {
      const systemMessages: iSystemMessage = await fetchUrl('get', apiToUrlMap.getCartSettings, {});
      rest.redux.props.systemMessageActions.systemMessageSet(systemMessages);
    } catch (err) {
      console.error(err);
      rest.redux.props.systemMessageActions.systemMessageSet({});
    }
  };

  const fetchInventorySettings = async () => {
    try {
      rest.redux.props.inventoryActions.settingsSet(
        await fetchUrl('GET', apiToUrlMap.inventorySettings, {})
      );
    } catch (error) {
      console.error(error);
    }
  };

  // show user proof of document dialog
  const fetchProofOfExport = async () => {
    if (!rest.redux.props.userState.userInfo?.setupCompleted) return;
    try {
      const proofOfExportData: IPOEServerResponse = await fetchUrl(
        'get',
        apiToUrlMap.getProofOfExportPendingDocuments,
        {}
      );
      rest.redux.props.userActions.setPOEData({ ...proofOfExportData, page: 'router' });
    } catch (err) {
      console.error('error in fetching proof of export', err);
      if (err.status === 403) return;
      setSettingsApiErr(err);
    }
  };

  const fetchTenantSalesOrderSettings = async () => {
    const salesOrderSettings = await fetchUrl('GET', apiToUrlMap.orderSettings, {});
    //SET SALES ORDER SETTINGS IN REDUX STORE
    rest.redux.props.salesOrderActions.salesOrderSettingsSet({
      [defaultParticipant]: salesOrderSettings,
    });
  };

  const fetchTenantStockListSettings = async () => {
    const stockListSettings = await fetchUrl(
      'GET',
      '/px-api-gateway/stocklist/stocklist/settings',
      {}
    );

    const pxnSettings: any = {
      isPxns: false,
      pxnrConfig: {},
      pxnrParticipantId: defaultParticipant,
      pxnrConfirmed: false,
    };

    rest.redux.props.networkSystemMessageActions.systemMessageSet({
      [defaultParticipant]: stockListSettings?.cartSettings,
    });
    rest.redux.props.stockActions.stockSettingsSet({
      pxnSettings,
      stocklistSettings: {
        [defaultParticipant]: stockListSettings,
      },
    });
  };

  const fetchNetworkSettings = async (): Promise<PXNSettingsResponse> => {
    return fetchUrl('get', apiToUrlMap.pxnSettings, {});
  };

  const fetchNetworkSalesOrderSettings = async () => {
    const salesOrderSettings = await fetchUrl('GET', apiToUrlMap.pxnOrderSettings, {});
    rest.redux.props.salesOrderActions.salesOrderSettingsSet(salesOrderSettings);
  };

  const fetchStockListSettings = async () => {
    try {
      if (rest.redux.props.configState.networkParticipant) {
        const pxnSettings = await fetchNetworkSettings();
        if (pxnSettings.isPxnr) {
          const [stocklistSettings, warehouses] = await Promise.all([
            fetchUrl('get', apiToUrlMap.pxnStockSettings, {}),
            fetchUrl('get', apiToUrlMap.pxnWarehouses, {}),
            fetchNetworkSalesOrderSettings(),
          ]);
          rest.redux.props.networkSystemMessageActions.systemMessageSet(
            getNetworkSystemMessageState(stocklistSettings)
          );
          rest.redux.props.stockActions.stockSettingsSet({
            pxnSettings: {
              ...pxnSettings,
              pxnrParticipantId: `${pxnSettings.pxnrParticipantId}`,
            },
            stocklistSettings: processPXNStockListSettings(
              stocklistSettings,
              pxnSettings.isPxnr ? pxnSettings.pxnrParticipantId : undefined
            ),
            pxnParticipantWarehousesObj: getPXNWarehouseObj(warehouses),
          });
          return;
        }
      }
      await Promise.all([fetchTenantSalesOrderSettings(), fetchTenantStockListSettings()]);
    } catch (error: any) {
      if (error.status === 403) return;
      setSettingsApiErr(error);
    }
  };

  const fetchBroadcastBanner = async () => {
    try {
      const pxFetchBroadcastBanner = localStorage.getItem('pxFetchBroadcastBanner');
      if (!pxFetchBroadcastBanner) return;
      const broadcastBanner = await fetchUrl('GET', apiToUrlMap.broadcastBanner, {});
      localStorage.removeItem('pxFetchBroadcastBanner');
      if (broadcastBanner && broadcastBanner[0]?.active)
        rest.redux.props.bannerAction.setBannerPayload(
          broadcastBanner.map((details: any) => ({ ...details, isBannerActive: details.active }))
        );
    } catch (error: any) {
      console.error(error);
    }
  };

  const fetchMissingDocuments = async () => {
    try {
      const pxMissingDocumentsSnackbar = localStorage.getItem('pxMissingDocumentsSnackbar');
      if (!pxMissingDocumentsSnackbar) return;
      const buyerType = rest.redux.props.userState.userInfo?.buyerType;
      const missingDocuments = await fetchUrl('GET', apiToUrlMap.accountDocuments, {
        headers: { buyerType },
      });
      localStorage.removeItem('pxMissingDocumentsSnackbar');
      const countMissingDocuments = missingDocuments.reduce(
        (acc: number, document: any) =>
          missingRelatedStatus.includes(document.documentStatus) ? acc + 1 : acc,
        0
      );
      if (countMissingDocuments)
        missingDocumentNotification({
          openSnackBar,
          closeSnackBar,
          history,
          cb: () => {
            setTimeout(() => dispatch(bannerAction.setBannerInactive()), 0);
          },
        });
    } catch (error: any) {
      console.error(error);
    }
  };

  const showInviteProspectExpirationInfo = () => {
    const displayIPSnackbar = localStorage.getItem('pxProspectInviteExpirationSnackbar');
    const expirationDate = rest.redux.props.userState.userInfo?.stocklistAccessExpirationDate;
    if (!displayIPSnackbar || !expirationDate) return;
    openSnackBar(
      <>
        Click{' '}
        <span
          className="mouse-pointer margin-bottom-0"
          onClick={() => {
            history.push('/account-setup');
            closeSnackBar();
          }}
        >
          HERE
        </span>{' '}
        to complete your registration and keep your access. Access will expire on{' '}
        {format(new Date(expirationDate), 'MMM dd, yyyy')}
      </>,
      'warning'
    );
    localStorage.removeItem('pxProspectInviteExpirationSnackbar');
  };

  React.useEffect(() => {
    if (checkNullOrUndefined(rest.redux.props.userState.userInfo?.setupCompleted)) return;
    //show Orders and Offers notification banners
    localStorage.setItem('showStockListNotifications', 'true');
    showInviteProspectExpirationInfo();

    async function fetchRequiredIntialCalls() {
      try {
        // fetch broadcast banner messages, called here to immediately show banner if any
        //fetch documents and show warning if any of the docs are missing
        Promise.all([
          fetchBroadcastBanner(),
          fetchMissingDocuments(),
          fetchProofOfExport(),
          fetchStockListSettings(),
          fetchInventorySettings(),
          fetchCartSettings(),
        ]);
      } catch (error) {}
    }

    if (rest.redux.props.userState.isSignedIn && !forceLogout) {
      fetchRequiredIntialCalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.redux.props.userState.userInfo?.setupCompleted]);

  React.useEffect(() => {
    const forceLogout = new URLSearchParams(history.location.search).get('force-logout') === 'true';
    if (forceLogout) {
      logoutUserWithoutAcknowledgement().then(async () => {
        // refetch config and I18n instead of refreshing the page.
        await getConfig(dispatch, setI18n);
        // reset signed in / account setup state
        rest.redux.props.userActions.userStateSet({
          isSignedIn: false,
          requiresAccountSetup: false,
        });
        history.push('/');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (rest.redux.props.userState.isSignedIn && settingsApiErr)
    return <ErrorView openSnackBar={openSnackBar} error={settingsApiErr} />;

  const forceLogout = new URLSearchParams(history.location.search).get('force-logout') === 'true';
  if (
    (rest.redux.props.userState.isSignedIn &&
      !rest.redux.props.stockGroupState.settings &&
      history.location.pathname !== '/stock') ||
    forceLogout
  ) {
    return <div className="px-full-screen-loader">{loading(I18n)}</div>;
  }

  if (rest.redux.props.userState?.userInfo?.isOfflineBuyer) {
    return (
      <Route
        {...rest}
        render={({ location }: any) => {
          if (rest.path.includes('/orders')) {
            return <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>;
          }

          return (
            <Redirect
              to={{
                pathname: '/orders',
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  if (isViewOnlyCustomer(rest.redux.props.userState?.userInfo?.enabledTags)) {
    return (
      <Route
        {...rest}
        render={({ location }: any) => {
          if (viewOnlyCustomerPathValid(rest.path)) {
            return <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>;
          }
          return <Redirect to="/stock" />;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.redux.props.userState.isSignedIn ? (
          fullWidth ? (
            <FullWidthLayout isMenuOpened={rest.isMenuOpened}>{children}</FullWidthLayout>
          ) : (
            <FrameLayout isMenuOpened={rest.isMenuOpened}>{children}</FrameLayout>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const HomeRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={({ location }: any) => {
        //if user requiresAccSetup = not signed in (even logged in)
        if (!rest.redux.props.userState.isSignedIn) {
          if (rest.redux.props.userState.requiresAccountSetup) {
            return <Redirect to={{ pathname: '/account-setup' }} />;
          }
          return (
            <Homepage>
              <Login />
            </Homepage>
          );
        }

        if (rest.redux.props.userState?.userInfo?.isOfflineBuyer) {
          return (
            <Redirect
              to={{
                pathname:
                  location.state && location.state.from.pathname.includes('/orders')
                    ? `${location.state.from.pathname}`
                    : '/orders',
                search: location.state ? location.state.from.search : '',
                state: { from: location },
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname:
                location.state &&
                !Object.values(accountsetupPrivateRoute).includes(location.state.from.pathname)
                  ? `${location.state.from.pathname}`
                  : '/stock',
              search: location.state ? location.state.from.search : '',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const ResetPasswordRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Homepage>
            <ResetPassword />
          </Homepage>
        );
      }}
    />
  );
};
const CreateAccountRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Suspense fallback={<></>}>
            <Homepage pxFrameView={true}>
              <CreateAccount />
            </Homepage>
          </Suspense>
        );
      }}
    />
  );
};

const EmailVerficationRoute = ({ children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <Suspense fallback={<></>}>
            <Homepage pxFrameView={true}>
              <EmailVerfication />
            </Homepage>
          </Suspense>
        );
      }}
    />
  );
};

const PxRouter = (props: any) => {
  const setCustomCSS = async (config: any) => {
    const style = document.createElement('style');
    style.innerHTML = config.custom_css;
    document.documentElement.appendChild(style);
  };

  React.useEffect(() => {
    if (props.props.configState) setCustomCSS(props.props.configState);
  }, [props.props.configState]);

  return (
    <Theme redux={props}>
      <Suspense fallback={<></>}>
        <Router>
          <div className="cell small-12 px-sass-container grid-x">
            <ProofOfExportDialog />
            <header className="cell small-12 px-header">
              <TopMenu />
            </header>
            <div className="cell small-12">
              <Widgets />
              <Switch>
                <PrivateRoute path="/dashboard" redux={props} isMenuOpened={0}>
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/stock" redux={props} isMenuOpened={1}>
                  <FiltersPersistWrapper localStorageKey="stocklistFilters" path="/stock">
                    <Stock />
                  </FiltersPersistWrapper>
                </PrivateRoute>
                <PrivateRoute path="/stock-alerts" redux={props} isMenuOpened={1}>
                  <StockAlerts />
                </PrivateRoute>
                <PrivateRoute path="/po-awards" redux={props} isMenuOpened={1} fullWidth={true}>
                  <PoAwards />
                </PrivateRoute>
                <PrivateRoute
                  path="/orders/order-release-confirmation"
                  redux={props}
                  exact={1}
                  isMenuOpened={1}
                >
                  <OrderConfirmation />
                </PrivateRoute>
                <PrivateRoute path="/orders/:id" exact={true} redux={props} isMenuOpened={1}>
                  <OrderDetailsV1 />
                </PrivateRoute>
                <PrivateRoute path="/orders" exact redux={props} isMenuOpened={1}>
                  <OrdersV1 />
                </PrivateRoute>
                <PrivateRoute path="/returns" redux={props} isMenuOpened={1}>
                  <Returns />
                </PrivateRoute>
                <PrivateRoute path="/account" redux={props} isMenuOpened={1}>
                  <Account />
                </PrivateRoute>
                <PrivateRoute path="/cart" redux={props} isMenuOpened={1}>
                  <CartV1 />
                </PrivateRoute>
                <PrivateRoute
                  path="/checkout/order-release-confirmation"
                  redux={props}
                  isMenuOpened={1}
                >
                  <CheckoutConfirmationV1 />
                </PrivateRoute>
                <PrivateRoute
                  path="/checkout/add-to-unshipped-order/:warehouse"
                  redux={props}
                  isMenuOpened={1}
                  exact
                >
                  <AddToExistingOrderV1 />
                </PrivateRoute>
                <PrivateRoute
                  path="/orders/:phonexOrderNumber/order-release-confirmation"
                  redux={props}
                  isMenuOpened={1}
                >
                  <PaymentConfirmation />
                </PrivateRoute>
                <PrivateRoute
                  path="/checkout/add-to-unshipped-order/:warehouse/:salesOrder"
                  redux={props}
                  isMenuOpened={1}
                >
                  <CheckoutV1 />
                </PrivateRoute>
                <PrivateRoute path="/checkout/:warehouse" redux={props} isMenuOpened={1}>
                  <CheckoutV1 />
                </PrivateRoute>
                <PrivateRoute path="/preferences" redux={props} isMenuOpened={1}>
                  <ShippingAndBillingPreferences />
                </PrivateRoute>
                <PrivateRoute path="/terms-of-sale" redux={props} isMenuOpened={1}>
                  <Terms />
                </PrivateRoute>
                <AccountSetupPrivate
                  path={accountsetupPrivateRoute.accountSetup}
                  redux={props}
                  exact
                >
                  <AccountSetup />
                </AccountSetupPrivate>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.masterAgreementForm}
                  redux={props}
                  exact={true}
                >
                  <MasterPurchaseAgreement />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.freightForwarderv2}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupDocumentsV1 />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.freightForwarder}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupDocuments />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.addendum}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupEUSAddendum />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute path={publicPrivateRoute.eus} redux={props} exact={true}>
                  <AccountSetupEUS />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute path={publicPrivateRoute.eusv1} redux={props} exact={true}>
                  <AccountSetupEUSV1 />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute path={publicPrivateRoute.eusv2} redux={props} exact={true}>
                  <AccountSetupEUSV2 />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.shippingConsentForm}
                  redux={props}
                  exact={true}
                >
                  <ShippingConsentForm />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.accountDetails}
                  redux={props}
                  exact={true}
                >
                  <AccountSetupDetails />
                </PublicAndPrivateRoute>
                <PublicAndPrivateRoute
                  path={publicPrivateRoute.complianceQuestionnaire}
                  redux={props}
                  exact={true}
                >
                  <ComplianceQuestionnaire />
                </PublicAndPrivateRoute>
                <PrivateRoute path="/auctions" redux={props} exact={true}>
                  <AuctionList />
                </PrivateRoute>
                <PrivateRoute path="/auctions/:auctionNumber" redux={props} exact={true}>
                  <AuctionBidding />
                </PrivateRoute>
                <CreateAccountRoute path="/create-account" />
                <EmailVerficationRoute path="/email-verification" />
                <ResetPasswordRoute path="/reset-password" redux={props} />
                <HomeRoute path="/" redux={props} />
              </Switch>
            </div>
            <footer className="cell small-12">
              <Footer />
            </footer>
          </div>
        </Router>
      </Suspense>
    </Theme>
  );
};

export const loading = (I18n?: any) => (
  <>
    {I18n ? (
      <h3 className="text-center">{I18n.loading || 'Loading'}...</h3>
    ) : (
      <div className="margin-top-2">&nbsp;</div>
    )}
    <Progress />
  </>
);

function PhonexRouter(props: any) {
  useFetchConfig();

  return (
    <div className="cell grid-x align-center">
      {props.configState.deactivated && (
        <DeactivatedTenant
          content={`This site is no longer available. Please contact ${props.configState.tenantName} for more information.`}
        />
      )}
      {!props.configState.deactivated &&
        (props.configState.isLoaded && props.userState ? <PxRouter props={props} /> : loading())}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    configState: state.configState,
    stockGroupState: state.stockGroupState,
    urlState: state.urlState,
    bannerState: state.bannerState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    configActions: bindActionCreators(configActions, dispatch),
    systemMessageActions: bindActionCreators(systemMessageAction, dispatch),
    networkSystemMessageActions: bindActionCreators(networkSystemMessageAction, dispatch),
    stockActions: bindActionCreators(stockActions, dispatch),
    bannerAction: bindActionCreators(bannerAction, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    urlActions: bindActionCreators(urlActions, dispatch),
    inventoryActions: bindActionCreators(inventoryActions, dispatch),
    salesOrderActions: bindActionCreators(salesOrderActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhonexRouter);
